// decoration folder
import { getCdnUrl } from '../helpers/get-cdn-url';

import BlueBackground from './decoration/blue_background.png';
import BottomLeftWave from './decoration/bottom_left_wave_terms_and_conditions.png';
import BottomWaveLogin from './decoration/bottom_wave_login.png';
import BottomWaveRegister from './decoration/bottom_wave_register.png';
import PeopleHug from './decoration/people_hug.png';
import PinkBackground from './decoration/pink_background.png';
import RedWave from './decoration/red_wave.svg';
import Wave from './decoration/register_bg.png';
// icons folder
import AskIcon from './icons/ask_icon.png';
// import ChileFlagIcon from './icons/chile_flag_icon.svg';
import CollectIcon from './icons/collect_icon.png';
import EmailSent from './icons/email_sent.png';
import {
  PencilIcon,
  SatIcon,
  SiiIcon,
  requestingPlatformMapperIcon, requestingPlatformMapperImage,
} from './icons/index';
import InstagramIcon from './icons/instagram_icon.png';
import KobrosPerson from './icons/kobros_person.png';
import LinkedinIcon from './icons/linkedin_icon.png';
import LookIcon from './icons/look_icon.png';
import MexicoFlagIcon from './icons/mexico_flag_icon.svg';
import PDFIcon from './icons/pdf.svg';
import SendIcon from './icons/send_icon.png';
import { FinanceIcon, RateGraphIcon, ReviewDocumentIcon, VisualizeAccountIcon } from './icons/tyc';
import WhatsappIcon from './icons/whatsapp.png';
import XMLIcon from './icons/xml.svg';
// illustrations folder
import FingoPlatformBig from './illustrations/fingo_platform_big.png';
import FingoPlatformMd from './illustrations/fingo_platform_md.png';
import SideBanner from './illustrations/login_side_banner.png';
import PasswordRecoveryBanner from './illustrations/password_recovery_banner.png';
import TermsAndConditions from './illustrations/terms_and_conditions.png';
import WelcomePerson from './illustrations/welcome_person.png';
// images folder
import BackgroundMosaic from './images/background_mosaic.avif';
import BrokenChain from './images/broken-chain.png';
import FiFingo from './images/fi_fingo.jpg';
import FingoWhite from './images/fingomas_white.png';
import HomeBackground from './images/home_background.jpg';
import Rose from './images/home_rose.png';
import KobrosLogo from './images/kobros_logo.png';
import KobrosLogoWhite from './images/kobros_logo_white.png';
import LoadingMan from './images/loading_man.gif';
import AceptaLogo from './images/logo_acepta.png';
import FingoChristmas from './images/logo_fingo_christmas.png';
import LokalLogo from './images/lokal_logo.svg';
import MrKobroSitting from './images/mr_kobros_sitting.png';
import MrKobroSittingWall from './images/mr_kobros_sitting_on_a_wall.svg';
import PartnerAceptaLogo from './images/partner/acepta.png';
import PartnerManagerLogo from './images/partner/manager.png';
import PartnerSenegociaLogo from './images/partner/senegocia.png';
import ReasonCompetitive from './images/reasons/competitive.png';
import ReasonFast from './images/reasons/fast.png';
import ReasonOnline from './images/reasons/online.png';
import ReasonTransparent from './images/reasons/transparent.png';
import SatLogo from './images/sat_logo.png';
import SiiLogo from './images/sii_logo.png';
import StepOneImage from './images/steps/step1.png';
import StepTwoImage from './images/steps/step2.png';
import StepThreeImage from './images/steps/step3.png';
import StepFourImage from './images/steps/step4.png';
import WhatIsFingo from './images/what_is_fingo.png';

const ChileFlagIcon = getCdnUrl('/common_assets/chile_flag_icon.svg');
const FingoLogo = getCdnUrl('/common_assets/logo_fingo_new.png');
const FingoChile = getCdnUrl('/common_assets/logo_fingo_chilean_national_day.png')

export {
  BlueBackground,
  BottomLeftWave,
  BottomWaveRegister,
  BottomWaveLogin,
  RedWave,
  PeopleHug,
  PinkBackground,
  Wave,
  // icons
  SiiIcon,
  SatIcon,
  PencilIcon,
  AskIcon,
  CollectIcon,
  LookIcon,
  PDFIcon,
  XMLIcon,
  SendIcon,
  InstagramIcon,
  KobrosPerson,
  LinkedinIcon,
  EmailSent,
  WhatsappIcon,
  requestingPlatformMapperIcon,
  requestingPlatformMapperImage,
  ReviewDocumentIcon,
  VisualizeAccountIcon,
  FinanceIcon,
  RateGraphIcon,
  // illustrations
  SideBanner,
  WelcomePerson,
  FingoPlatformBig,
  FingoPlatformMd,
  TermsAndConditions,
  PasswordRecoveryBanner,
  // images
  FingoLogo,
  FingoChristmas,
  FingoChile,
  FiFingo,
  FingoWhite,
  BrokenChain,
  AceptaLogo,
  LokalLogo,
  SiiLogo,
  SatLogo,
  Rose,
  HomeBackground,
  BackgroundMosaic,
  PartnerAceptaLogo,
  PartnerManagerLogo,
  PartnerSenegociaLogo,
  ReasonCompetitive,
  ReasonFast,
  ReasonOnline,
  ReasonTransparent,
  StepOneImage,
  StepTwoImage,
  StepThreeImage,
  StepFourImage,
  LoadingMan,
  WhatIsFingo,
  MexicoFlagIcon,
  ChileFlagIcon,
  KobrosLogo,
  MrKobroSitting,
  MrKobroSittingWall,
  KobrosLogoWhite,
};
